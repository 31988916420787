<template>
    <div style="background-color: #ffffff;">
        <div>
            <div class="pageCenter titleBar " :class="[statu==4?'enterHeadWidth':'pageWidth']">
                <!-- <div class="pageCenter titleBar" :style="{width: pageWidth+'px'}"> -->
                <div class="titleBar_left">
                    <img @click="$oucy.replace('/', null)" style="height: 40px;cursor: pointer;" src="@/assets/logo.png">
                    <div class="titleBar_left_txt">
                        企业管理中心
                    </div>
                </div>
                <div class="titleBar_right" @click="$oucy.replace('/')">
                    返回首页
                </div>
            </div>
        </div>
        <div class="pageCenter breadcrumbBar" :style="{width: pageWidth+'px'}">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/">企业管理中心</a></el-breadcrumb-item>
                <!-- <el-breadcrumb-item>个人资料</el-breadcrumb-item> -->
            </el-breadcrumb>
            <el-button icon="el-icon-notebook-2" type="text" @click="$oucy.go('/AfficheList?type=1')">帮助手册</el-button>
        </div>
        <div class="pageCenter text-left m-b-20" :style="{width: pageWidth+'px'}">
            <div class="f24 bold text-center">查看评论</div>
            <lineTitle text="订单信息"></lineTitle>
            <div class="m-b-40">
                <order v-for="(v,i) of 1" :key="i" class="m-t-20"></order>
            </div>
            <lineTitle text="买家评论"></lineTitle>
            <div class="commentBody m-t-20">
                <div class="top df-jc-s-b df-ai-c">
                    <div class="user df-ai-c">
                        <img src="@/assets/images/face2.png"><span class="f14 c-6 m-l-10">大白虾</span>
                    </div>
                    <div class="df-ai-c">
                        <span class="c-6 bold">差评</span>
                        <el-rate class="m-l-10" v-model="value" show-text>
                        </el-rate>
                        <span class="m-l-10 c-2">2021-08-09</span>
                    </div>
                </div>
                <div class="m-t-20">
                    <div class="text f14 c-6">师傅给送货上门、款式简单大方，颜色是我很喜欢的颜色、小细节处理的很好、做工感觉挺精细的、坐上去很舒服、很满意师傅给送货上门、款式简单大方，颜色是我很喜欢的颜色、小细节处理的很好、做工感觉挺精细的、坐上去很舒服、很满意师傅给送货上门、款式简单大方，颜色是我很喜欢的颜色、小细节处理的很好、做工感觉挺精细的、坐上去很舒服、很满意师傅给送货上门、款式简单大方，颜色是我很喜欢的颜色、小细节处理的很好、做工感</div>
                    <div class="m-t-10">
                        <el-image style="width: 100px; height: 100px" :src="url" :preview-src-list="srcList">
                        </el-image>
                    </div>
                </div>
                <div class="m-t-20 text-right">
                    <el-input type="textarea" placeholder="请输入内容" rows="3" v-model="textarea" maxlength="400" show-word-limit>
                    </el-input>
                    <el-button class="m-t-20" type="primary">确认回复</el-button>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { userAuth } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "refund",
    data() {
        return {
            pageWidth: oucy.pageWidth,
            form: {},
            statu: 0,
            input3: null,
            select: null,
            queryFurnitureLibraryObj: {},
            input: null,
            activities: [{
                content: '支持使用图标',
                timestamp: '2018-04-12 20:46',
                size: 'large',
                type: 'primary',
                color: '#2090FF',
                icon: 'el-icon-check'
            }, {
                content: '支持自定义颜色',
                timestamp: '2018-04-03 20:46',
                size: 'large',
                type: 'primary',
                // color: '#2090FF',
            }, {
                content: '支持自定义尺寸',
                timestamp: '2018-04-03 20:46',
                size: 'large'
            }, {
                content: '默认样式的节点',
                timestamp: '2018-04-03 20:46'
            }],
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            srcList: [
                'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
                'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
            ],
            textarea:'',
            value:0,
        }
    },
    computed: {

    },
    mounted() {},
    methods: {
        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            // me.queryFurnitureLibrary();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            // me.queryFurnitureLibrary();
        },
        onSubmit() {},
    }
}
</script>
<style scoped lang="less">
.titleBar {
    height: 100px;
    /*background-color: #0366C3;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.titleBar_left {
    /*background-color: #42b983;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.titleBar_left_txt {
    /*background-color: #409EFF;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    font-size: 25px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px #eeeeee solid;
    color: gray;
}

.titleBar_right {
    font-size: 25px;
    color: gray;
}

.titleBar_right:hover {
    cursor: pointer;
    color: #000000;
}

.breadcrumbBar {
    padding: 10px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.breadcrumbBar.enterHeadWidth {
    padding: 0 60px;
}

.el-menu-vertical-demo {
    text-align: left;
    min-height: 700px;
    border-radius: 4px;
}

.el-menu-item,
.el-submenu__title {
    font-size: 16px;
}

.el-menu-item span {
    font-size: 16px;
}
.commentBody{
    background: #F8F8F8;
    padding: 20px;
}
</style>